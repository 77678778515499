<template>
  <div data-page-projects>

    <div class="flex flex-wrap -mx-1 lg:-mx-4">
      <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
        <div
            class=" shadow bg-white dark:border-gray-700 rounded-md  rounded-lg border border-gray-200 shadow-md hover:bg-gray-50 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <div class="flex flex-col justify-between p-10 h-80  max-h-80 ">
            <div>
              <h1 class="mb-3 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Create a new project
              </h1>
              <div class="space-y-4 md:space-y-6">
                <input
                    v-model="form.title"
                    type="text" id="title"
                    placeholder=""
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-1"
                >
                <div class="flex items-center">
                  <div class="flex items-center mr-4" v-for="item in projectTypes" :key="item.id">
                    <input :disabled="item.disabled" :id="'project-type-'+ item.id" type="radio" v-model="form.type" name="type" :value="item.id"
                           class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                    <label :for="'project-type-'+ item.id"
                           :disabled="item.disabled"
                           class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ item.title }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                  @click="submit()"
                  :disabled="!formIsValid"
                  type="button"
                  class="w-full py-3 px-5 text-base font-medium text-center text-white bg-gray-400 rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300 cursor-auto dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  :class="{'bg-purple-600 hover:bg-purple-800 cursor-pointer' : formIsValid}">
                CREATE
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
          class="item relative my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3" v-for="item in items"
          :key="item.id">
        <div
            class="flex flex-col justify-between shadow bg-white rounded-md p-10 h-80 max-h-80 hover:bg-gray-50 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <div class="flex justify-between">
            <router-link :to="{ name: 'Sections', params: { project_id: item.id}}">
              <div class="text-lg text-purple-700 md:text-xl dark:text-white font-extrabold">{{ item.title }}</div>
              <div class="font-light dark:text-white mb-2">{{ item.type_str }}</div>
              <div class="leading-5 font-thin dark:text-gray-400 ">{{ item.sections_count }}
                {{ item.type === 1 ? 'Personas' : 'Screen' }}
              </div>
            </router-link>
            <div>
              <span
                  @click="destroy(item.id)"
                  class="remove-button bg-red-700 text-white text-xs font-semibold px-2 py-1 rounded dark:bg-red-700 dark:text-gray-300 ml-2 cursor-pointer">DELETE</span>
            </div>
          </div>

          <div class="flex justify-between items-center">
            <div>
              <div class="flex items-center">
                <div
                    class="px-4 py-2 mr-3 bg-blue-50 rounded flex flex-col justify-center items-center text-xs text-gray-500 dark:bg-gray-700 dark:text-gray-400">
                  <div>{{ item.created_at | moment('MMM') }}</div>
                  <div>{{ item.created_at | moment('YY') }}</div>
                </div>
                <div class="dark:text-gray-400">
                  <div class="text-xs font-thin">Created by</div>
                  <div class="text-sm font-light">{{ item.owner_full_name }}</div>
                </div>
              </div>
            </div>
            <template v-if="item.owner === user.user.id">
              <div class="flex cursor-pointer" @click="showMembers(item.id)">
                <div v-for="member in item.members" :key="member.id"
                     :title="member.first_name +' '+ member.last_name"
                     class="inline-flex -ml-2 items-center justify-center w-10 h-10 rounded-full bg-indigo-50 text-md font-large text-purple-500 border dark:bg-gray-700 dark:text-white">
                  {{ member.first_name.split('')[0] }}
                </div>
                <div
                    class=" inline-flex -ml-2 items-center justify-center w-10 h-10 rounded-full bg-purple-500 text-md font-large text-white border dark:bg-gray-700 dark:text-white">
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"></path>
                  </svg>
                </div>
              </div>
            </template>
          </div>

        </div>


      </div>

    </div>

    <members></members>

  </div>
</template>

<script>
import Members from "../components/Members";

export default {
  name: 'Projects',
  components: {
    Members,
  },
  data() {
    return {
      showNewModal: false,
      form: {
        title: '',
        type: 2
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["user"];
    },
    items() {
      return this.$store.getters["projects/list"];
    },
    projectTypes() {
      return this.$store.getters["projectTypes"];
    },
    formIsValid() {
      return this.form.title.length > 0
    }
  },
  methods: {
    async submit() {
      const {data} = await this.$store.dispatch('projects/create', this.form)
      if (data.status !== 'error') {
        this.showNewModal = false;
        this.form.title = ''
        this.$router.push({name: 'Sections', params: {project_id: data.result.id}})
      }

    },
    showMembers(project_id) {
      this.$store.dispatch('members/list', {
        project_id: project_id,
      })
    },
    async destroy(id) {

      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$store.dispatch('projects/delete', {
            project_id: id,
          })
        }
      })


    },
  },
  created() {
    this.$store.dispatch('projects/init')
  }
}
</script>
<style lang="scss">
.customModal {
  background-color: rgba(0, 0, 0, 0.7);
}

.remove-button {
  display: none;
}

.item:hover {
  .remove-button {
    display: inline-block;
  }
}
</style>
