<template>
  <div class="example-full">
    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
      <h3>Drop files to upload</h3>
    </div>
    <div class="upload">
      <div class="bg-white shadow">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead v-if="files.length"
                 class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" class="py-3 px-6">Thumb</th>
            <th scope="col" class="py-3 px-6">Name</th>
            <th scope="col" class="py-3 px-6">Width</th>
            <th scope="col" class="py-3 px-6">Height</th>
            <th scope="col" class="py-3 px-6">Size</th>
            <th scope="col" class="py-3 px-6">Speed</th>
            <th scope="col" class="py-3 px-6">Status</th>
            <th scope="col" class="py-3 px-6 text-right">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="!files.length" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <td colspan="9">
              <div class="py-10 text-center text-lg font-thin">
                <h4 class="mb-3">Drop files anywhere to upload<br/>or</h4>
                <label :for="name"
                       class="cursor-pointer focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Select
                  Files</label>
                <div class="mt-5 text-sm">
                  Up to {{ maximum }} images, up to {{ size | formatSize }} each</div>
              </div>
            </td>
          </tr>
          <tr v-for="(file) in files" :key="file.id" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <td class="py-4 px-6">
              <img class="td-image-thumb" v-if="file.thumb" :src="file.thumb"/>
              <span v-else>No Image</span>
            </td>
            <td class="py-4 px-6">
              <div class="filename">
                <input type="text"
                       class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                       :placeholder="file.name"
                       v-model="file.title">
              </div>
              <div class="progress" v-if="file.active || file.progress !== '0.00'">
                <div
                    :class="{'progress-bar': true, 'progress-bar-striped': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}"
                    role="progressbar" :style="{width: file.progress + '%'}">{{ file.progress }}%
                </div>
              </div>
            </td>
            <td class="py-4 px-6">{{ file.width || 0 }}</td>
            <td class="py-4 px-6">{{ file.height || 0 }}</td>
            <td class="py-4 px-6">{{ file.size | formatSize }}</td>
            <td class="py-4 px-6">{{ file.speed | formatSize }}</td>

            <td v-if="file.error" class="py-4 px-6">{{ file.error }}</td>
            <td v-else-if="file.success" class="py-4 px-6">success</td>
            <td v-else-if="file.active" class="py-4 px-6">active</td>
            <td v-else class="py-4 px-6"></td>
            <td class="py-4 px-6 text-right">
              <div v-if="!file.success">

                <button type="button"
                        v-if="file.active"
                        @click.prevent="file.active ? $refs.upload.update(file, {error: 'cancel'}) : false"
                        class="text-white bg-gray-500 hover:bg-purple-800 focus:ring-4 focus:outline-none font-medium rounded-md text-xs px-2 py-1 text-center inline-flex items-center mr-2"

                >Cancel
                </button>

                <button type="button"
                        v-if="file.active"
                        @click.prevent="$refs.upload.update(file, {active: false})"
                        class="text-white bg-gray-500 hover:bg-purple-800 focus:ring-4 focus:outline-none font-medium rounded-md text-xs px-2 py-1 text-center inline-flex items-center mr-2"

                >Abort
                </button>
                <button
                    type="button"
                    v-else-if="file.error && file.error !== 'compressing' && file.error !== 'image parsing' && $refs.upload.features.html5"
                    @click.prevent="$refs.upload.update(file, {active: true, error: '', progress: '0.00'})"
                    class="text-white bg-gray-500 hover:bg-purple-800 focus:ring-4 focus:outline-none font-medium rounded-md text-xs px-2 py-1 text-center inline-flex items-center mr-2"
                >Retry upload
                </button>
                <button
                    type="button"
                    :class="{ disabled: file.success || file.error === 'compressing' || file.error === 'image parsing'}"
                    v-else
                    @click.prevent="file.success || file.error === 'compressing' || file.error === 'image parsing' ? false : $refs.upload.update(file, {active: true})"
                    class="text-white bg-gray-500 hover:bg-purple-800 focus:ring-4 focus:outline-none font-medium rounded-md text-xs px-2 py-1 text-center inline-flex items-center mr-2"

                >
                  Upload
                </button>
                <button type="button"
                        :class="{ disabled: file.active || file.success || file.error === 'compressing' || file.error === 'image parsing'}"
                        href="#"
                        @click.prevent="file.active || file.success || file.error === 'compressing' ? false :  onEditFileShow(file)"
                        class="text-white bg-gray-500 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-md text-xs px-2 py-1 text-center inline-flex items-center mr-2"

                >
                  Edit
                </button>
                <button type="button"
                        class="text-white bg-gray-500 hover:bg-red-800 focus:ring-4 focus:outline-none font-medium rounded-md text-xs px-2 py-1 text-center inline-flex items-center"
                        @click.prevent="$refs.upload.remove(file)"
                >
                  Delete
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="example-foorer">
        <file-upload
            :custom-action="uploadSection"
            :extensions="extensions"
            :accept="accept"
            :multiple="multiple"
            :directory="directory"
            :size="size || 0"
            :thread="thread < 1 ? 1 : (thread > 5 ? 5 : thread)"
            :headers="headers"
            :drop="drop"
            :drop-directory="dropDirectory"
            :maximum="maximum"
            v-model="files"
            @input-filter="inputFilter"
            @input-file="inputFile"
            ref="upload">
        </file-upload>
        <div v-if="files.length" class="text-right">
          <button
              type="button"
              v-if="!$refs.upload || !$refs.upload.active"
              class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              @click.prevent="$refs.upload.active = true">
            Start All Upload
          </button>
          <button type="button"
                  v-else
                  class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                  @click.prevent="$refs.upload.active = false">
            Stop Upload
          </button>
        </div>

      </div>
    </div>


    <transition>
      <!-- Main modal -->
      <div v-if="editFile.show" id="modal-edit-file" tabindex="-1" aria-hidden="true"
           class="customModal flex justify-center items-center fixed top-0 left-0 right-0 z-50   p-4 overflow-x-hidden overflow-y-auto  h-full">
        <div class="relative ">
          <!-- Modal content -->
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal header -->
            <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
              <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                Edit Image
              </h3>
              <button
                  @click.prevent="editFile.show = false"
                  type="button"
                  class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm px-2 py-1 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>
            <!-- Modal body -->
            <div class="p-6 space-y-6">
              <form @submit.prevent="onEditorFile">
                <div class="modal-body">
                  <div class="form-group"
                       v-if="editFile.show && editFile.blob && editFile.type && editFile.type.substr(0, 6) === 'image/'">
                    <div class="edit-image">
                      <img :src="editFile.blob" ref="editImage" class="editImage"/>
                    </div>

                    <div class="edit-image-tool flex justify-between items-center pt-5">
                      <div>
                        <button type="button"
                                class="text-white bg-gray-500 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-md text-xs px-2 py-1 text-center inline-flex items-center mr-2"
                                @click="editFile.cropper.rotate(90)"
                                title="cropper.rotate(90)">
                          Rotate
                        </button>
                        <button type="button"
                                class="text-white bg-gray-500 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-md text-xs px-2 py-1 text-center inline-flex items-center mr-2"
                                @click="editFile.cropper.crop()"
                                title="cropper.crop()">Crop
                        </button>
                        <button type="button"
                                class="text-white bg-gray-500 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-md text-xs px-2 py-1 text-center inline-flex items-center mr-2"
                                @click="editFile.cropper.clear()"
                                title="cropper.clear()">Clear
                        </button>
                      </div>
                      <div>
                        <button type="button"
                                class="text-white bg-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none font-medium rounded-md text-xs px-2 py-1 text-center inline-flex items-center mr-2"
                                @click.prevent="editFile.show = false">Close
                        </button>
                        <button type="submit"
                                class="text-white bg-green-500 hover:bg-green-800 focus:ring-4 focus:outline-none font-medium rounded-md text-xs px-2 py-1 text-center inline-flex items-center mr-2"
                        >Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
    </transition>


  </div>
</template>
<style lang="scss">

.td-image-thumb {
  max-width: 4em;
  max-height: 4em;
}

.editImage {
  max-height: 75vh;
}

.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
  border: 5px dashed white;

  h3 {
    margin: -.5em 0 0;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 40px;
    color: #fff;
    padding: 0;
  }
}


</style>

<script>
import Cropper from 'cropperjs'
import ImageCompressor from '@xkeshi/image-compressor'
import FileUpload from 'vue-upload-component'

export default {
  components: {
    FileUpload,
  },

  data() {
    return {
      files: [],
      maximum: 10,
      accept: 'image/png,image/gif,image/jpeg,image/webp',
      extensions: 'gif,jpg,jpeg,png,webp',
      minSize: 1024,
      size: 1024 * 1024 * 10,
      multiple: true,
      directory: false,
      drop: true,
      dropDirectory: true,
      thread: 3,
      name: 'file',
      postAction: null,
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      isOption: false,
      headers: this.axios.defaults.headers.common,
      editFile: {
        show: false,
        name: '',
      }
    }
  },

  watch: {
    'editFile.show'(newValue, oldValue) {
      // 关闭了 自动删除 error
      if (!newValue && oldValue) {
        this.$refs.upload.update(this.editFile.id, {error: this.editFile.error || ''})
      }

      if (newValue) {
        this.$nextTick(() => {
          if (!this.$refs.editImage) {
            return
          }
          let cropper = new Cropper(this.$refs.editImage, {
            autoCrop: false,
          })
          this.editFile = {
            ...this.editFile,
            cropper
          }
        })
      }
    },
  },

  methods: {
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          return prevent()
        }

        // Automatic compression
        if (newFile.file && newFile.error === "" && newFile.type.substr(0, 6) === 'image/' && this.autoCompress > 0 && this.autoCompress < newFile.size) {
          newFile.error = 'compressing'
          const imageCompressor = new ImageCompressor(null, {
            convertSize: 1024 * 1024,
            maxWidth: 512,
            maxHeight: 512,
          })
          imageCompressor.compress(newFile.file)
              .then((file) => {
                this.$refs.upload.update(newFile, {error: '', file, size: file.size, type: file.type})
              })
              .catch((err) => {
                this.$refs.upload.update(newFile, {error: err.message || 'compress'})
              })
        }
      }


      if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = ''
        let URL = (window.URL || window.webkitURL)
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }

        // Thumbnails
        newFile.thumb = ''
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob
        }
      }

      // image size
      // image 尺寸
      if (newFile && newFile.error === '' && newFile.type.substr(0, 6) === "image/" && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)) {
        newFile.error = 'image parsing'
        let img = new Image();
        img.onload = () => {
          this.$refs.upload.update(newFile, {error: '', height: img.height, width: img.width})
        }
        img.οnerrοr = () => {
          this.$refs.upload.update(newFile, {error: 'parsing image size'})
        }
        img.src = newFile.blob
      }
    },

    inputFile(newFile, oldFile) {

      if (newFile && oldFile) {
        // update

        if (newFile.active && !oldFile.active) {
          // beforeSend

          // min size
          if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize && newFile.type !== "text/directory") {
            this.$refs.upload.update(newFile, {error: 'size'})
          }
        }

        if (newFile.progress !== oldFile.progress) {
          // progress
        }

        if (newFile.error && !oldFile.error) {
          // error
        }

        if (newFile.success && !oldFile.success) {
          // success
        }
      }


      if (!newFile && oldFile) {
        // remove
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/upload/delete?id=' + oldFile.response.id,
          // })
        }
      }


      // Automatically activate upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true
        }
      }
    },

    alert(message) {
      alert(message)
    },
    onEditFileShow(file) {
      this.editFile = {...file, show: true}
      this.$refs.upload.update(file, {error: 'edit'})
    },
    onEditorFile() {
      if (!this.$refs.upload.features.html5) {
        this.alert('Your browser does not support')
        this.editFile.show = false
        return
      }

      let data = {
        name: this.editFile.name,
        error: '',
      }
      if (this.editFile.cropper) {
        let binStr = atob(this.editFile.cropper.getCroppedCanvas().toDataURL(this.editFile.type).split(',')[1])
        let arr = new Uint8Array(binStr.length)
        for (let i = 0; i < binStr.length; i++) {
          arr[i] = binStr.charCodeAt(i)
        }
        data.file = new File([arr], data.name, {type: this.editFile.type})
        data.size = data.file.size
      }
      this.$refs.upload.update(this.editFile.id, data)
      this.editFile.error = ''
      this.editFile.show = false
    },
    async uploadSection(file) {
      let params = new FormData();
      params.append('title', file.title ? file.title : file.name);
      params.append('image', new File([file.file], file.name));

      return await this.$store.dispatch('sections/create', {
        params: params,
        project_id: this.$route.params.project_id,
      })


    },
  }
}

</script>
