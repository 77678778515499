import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "./jwt";


/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = "https://api.uxreport.io/";
        //Vue.axios.defaults.baseURL = "http://127.0.0.1:8000/";
        this.setHeader()
    },

    /**
     * Set the default HTTP request headers
     */
    setHeader() {
        if (JwtService.getToken()) {
            Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
        }


    },

    query(resource, params) {
        return Vue.axios.get(resource, params).catch(error => {
            console.log(error);
        });
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @param slug
     * @returns {*}
     */
    get(resource) {
        return Vue.axios.get(resource).catch(error => {
            console.log(error);
        });
    },

    getImage(resource) {
        return Vue.axios.get(resource, {responseType: 'arraybuffer'}).catch(error => {
            console.log(error);
        });
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params) {
        return Vue.axios.post(`${resource}`, params);
    },


    /**
     * Set the PATCH HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    patch(resource, params) {
        return Vue.axios.patch(`${resource}`, params);
    },

    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, slug, params) {
        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    delete(resource) {
        return Vue.axios.delete(resource).catch(error => {
            console.log(error);
            throw new Error(`[RWV] ApiService ${error}`);
        });
    },
    download(params) {
        return Vue.axios(params).catch(error => {
            console.log(error);
            throw new Error(`[RWV] ApiService ${error}`);
        });
    }
};

export default ApiService;
