<template>
  <div class="presentation" v-if="report">
    <div class="reveal">
      <div class="slides">
        <section class="" v-for="section in report.sections" :key="section.id">

          <section class="findings" v-for="finding in section.findings" :key="finding.id">
            <div class="finding border-b">
              <div class="screen__name">
                {{ section.title }}
              </div>

              <div class="title">{{ finding.finding }}</div>
              <div class="recommendation">
                {{ finding.recommendation }}
              </div>
              <div class="info">
                <div class="info__item">
                  <div class="info__title">Rating</div>
                  <div class="info__value">

                    <div class="rating" :data-rating="finding.rating_str.toLowerCase()">
                      <svg v-for="i in 3" :key="i" class="w-5 h-5" fill="rgba(0,0,0,0.5)" stroke="transparent"
                           viewBox="0 0 24 24"
                           xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="2"
                              d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path>
                      </svg>
                    </div>
                    <div class="ml-1">{{ finding.rating_str }}</div>


                  </div>
                </div>
                <div class="info__item">
                  <div class="info__title">Sentiment</div>
                  <div class="info__value">
                    <span :class="'sentiment ' + finding.sentiment_str.toLowerCase()"></span>
                    <span class="ml-1">{{ finding.sentiment_str }}</span>
                  </div>
                </div>
                <div class="info__item">
                  <div class="info__title">Category</div>
                  <div class="info__value">{{ finding.category_str }}</div>
                </div>
                <div class="info__item hidden">
                  <div class="info__title">Labels</div>
                  <div class="info__value">
                    <span v-for="(label,index) in finding.labels_str" :key="'label-'+ index">{{ label }}</span>
                  </div>
                </div>

              </div>
            </div>
          </section>

        </section>
      </div>
    </div>
  </div>
</template>
<script>

import Reveal from "reveal.js";
import "reveal.js/dist/reveal.css";


export default {
  name: 'Presentation',
  props: ['sections'],
  data() {
    return {
      sampleData: []
    }
  },
  computed: {
    report() {
      return this.$store.getters['report/report'];
    },
  },
  methods: {},
  async created() {
    await this.$store.dispatch('report/get', {
      project_id: this.$route.params.project_id,
    })
    this.$store.commit('SET_LOADING', false, {root: true})
    this.isReady = true;
    Reveal.initialize({
      width: '100%',
      height: '100%'
    });
  },
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;600&display=swap');


.scrollable {
  bottom: 0;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.presentation {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;

  * {
    text-align: left;
    font-family: 'Roboto', sans-serif;
  }
}

.screen__name {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #333;
}

.findings {
  .finding {
    margin-bottom: 20px;
    padding-bottom: 20px;

    .title {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 10px;
    }

    .recommendation {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
      margin-bottom: 20px;
    }

    .info {
      display: flex;

      width: 50%;
      align-items: center;

      &__item {
        margin: 0 20px 0 0;
      }

      &__title {
        color: rgba(0, 0, 0, 0.5);
        margin-right: 10px;
        margin-bottom: 5px;
        font-size: 13px;
      }

      &__value {
        color: rgba(0, 0, 0, 1);
        display: flex;
        align-items: center;
      }
    }
  }

  .rating {
    display: flex;
  }

  .sentiment {
    display: inline;
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 3px;

    &.positive {
      background-color: #4caf50;
    }

    &.neutral {
      background-color: #ff9800;
    }

    &.negative {
      background-color: #f44336;
    }
  }

}

[data-rating="critical"] {
  svg {
    fill: red;
  }
}

[data-rating="major"] {

  svg {
    &:nth-child(1), &:nth-child(2) {
      fill: red;
    }
  }
}

[data-rating="minor"] {
  svg {
    &:nth-child(1), {
      fill: red;
    }
  }
}

</style>

