<template>
  <img :src="image" v-if="image" ref="image" loading="lazy" >
</template>

<script>
import ApiService from "../services/api";

export default {
  name: 'SectionImage',
  props: ['sectionId'],
  data() {
    return {
      image: null,
    }
  },
  computed: {
    page() {
      return this.$route.name
    },
  },
  methods: {
    orientation() {
      setTimeout(() => {
        const w = this.$refs['image'].naturalWidth
        const h = this.$refs['image'].naturalHeight
        if (h > w) {
          document.body.classList.add('portrait')
        } else {
          document.body.classList.add('wide')
        }
      }, 500)
    }
  },
  created() {

    ApiService.getImage(`/projects/${this.$route.params.project_id}/sections/${this.sectionId}/image/`)
        .then((response) => {
          let image = btoa(
              new Uint8Array(response.data)
                  .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );
          this.image = `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
          if (this.page === 'Pins') {
            this.orientation()
          }

        });
  }

};
</script>
