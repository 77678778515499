import Vue from 'vue'
import Vuex from 'vuex'
import projects from "./modules/projects";
import sections from "./modules/sections";
import findings from "./modules/findings";
import contents from "./modules/contents";
import user from "./modules/user";
import tags from "./modules/tags";
import members from "./modules/members";
import report from "./modules/report";
import ApiService from "../services/api";

Vue.use(Vuex)

export default new Vuex.Store({
    namespaced: true,
    state: {
        theme: localStorage.getItem('theme') || 'dark',
        loading: false,
        error: null,
        user: null,
        logged_in: false,
        projectTypes: [
            {
                id: 2,
                disabled: false,
                title: 'Usability Testing',
                description: 'Lorem ipsum dolor sit amet'
            },
            {
                id: 1,
                disabled: true,
                title: 'User Interview (coming soon)',
                description: 'Lorem ipsum dolor sit amet'
            }
        ],
        sentiments: [
            {
                id: 1,
                title: "Negative",
                color: "rgba(255, 99, 132,1)"
            },
            {
                id: 2,
                title: "Neutral",
                color: "rgba(54, 162, 235,1)"
            },
            {
                id: 3,
                title: "Positive",
                color: "rgba(255, 205, 86,1)"
            }
        ],
        ratings: [
            {
                id: 1,
                title: "Critical",
                color: "rgba(255, 99, 132,1)"
            },
            {
                id: 2,
                title: "Major",
                color: "rgba(54, 162, 235,1)"
            },
            {
                id: 3,
                title: "Minor",
                color: "rgba(255, 205, 86,1)"
            }
        ],
        efforts: [
            {
                id: 0,
                title: "None",
                color: "rgba(255, 99, 132,1)"
            },
            {
                id: 1,
                title: "High",
                color: "rgba(54, 162, 235,1)"
            },
            {
                id: 2,
                title: "Mid",
                color: "rgba(255, 205, 86,1)"
            },
            {
                id: 3,
                title: "Low",
                color: "rgba(75, 192, 192,1)"
            }
        ],
        plans: null
    },
    mutations: {
        SET_USER(state, value) {
            state.user = value;
        },
        SET_LOGGED_IN(state, value) {
            state.logged_in = value;
        },
        SET_LOADING(state, value) {
            state.loading = value;
        },
        SET_ERROR(state, value) {
            state.error = value;
        },
        SET_THEME(state, value) {
            state.theme = value;
        },
        SET_PLANS(state, value) {
            state.plans = value;
        },
    },
    actions: {
        async user({commit}) {
            ApiService.get('me/').then((res) => {
                if (res.data.status === 'success') {
                    commit("SET_USER", res.data.result[0]);
                    commit("SET_LOGGED_IN", true);
                } else {
                    window.localStorage.clear();
                    window.location = '/login';
                }

            }).catch(() => {
                window.localStorage.clear();
                window.location = '/login';
            })
        },
        async plans({commit}) {
            ApiService.get('plans/').then((res) => {
                commit("SET_PLANS", res.data.result);
            })
        },
    },
    getters: {
        loading(state) {
            return state.loading
        },
        error(state) {
            return state.error
        },
        user(state) {
            return state.user
        },
        isLogin(state) {
            return state.logged_in
        },
        theme(state) {
            return state.theme
        },
        sentiments(state) {
            return state.sentiments
        },
        ratings(state) {
            return state.ratings
        },
        efforts(state) {
            return state.efforts
        },
        projectTypes(state) {
            return state.projectTypes
        },
        plans(state) {
            return state.plans
        },

    },
    modules: {
        projects: projects,
        sections: sections,
        findings: findings,
        contents: contents,
        tags: tags,
        user: user,
        members: members,
        report: report,
    }
})
