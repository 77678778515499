<template>
  <div ref="errors" class="hidden">
    <div
        v-for="(items,title) in error"
        :key="title"
        class="text-left p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
        role="alert">
      <div class="capitalize" v-if="title !=='non_field_errors'">{{ title }};</div>
      <p v-for="(item, index) in items" :key="index">{{ item }}</p>
    </div>
  </div>
</template>
<script>

export default {
  computed: {
    error() {
      return this.$store.getters['error']
    },
  },
  created() {

    this.$nextTick((() => {
      this.$store.commit('SET_LOADING', false, {root: true})
      this.$swal({
        html: this.$refs.errors.innerHTML
      }).then(() => {
        this.$store.commit('SET_ERROR', null, {root: true})
      });
    }))
  }
};
</script>
<style lang="scss">

</style>

