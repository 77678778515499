<template>
  <div v-if="isReady">
    <div
        class="relative flex justify-between text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
      <ul class="flex flex-wrap -mb-px">
        <li class="mr-2" v-for="tab in tabs" :key="tab.id" @click="currentTab = tab.id">
          <span class="inline-block p-4 rounded-t-lg border-b-2 border-transparent cursor-pointer"
                :class="{'text-blue-600 border-blue-600 active dark:text-blue-500 dark:border-blue-500' : currentTab === tab.id}"
          >{{ tab.title }}</span>
        </li>

      </ul>
      <router-link :to="{ name: 'Presentation', params: { project_id: $route.params.project_id}}" target="_blank"
              class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">
        Presentation Mode
      </router-link>
    </div>

    <div v-show="currentTab === 'general'">
      <div class="mt-4 w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <span class="text-2xl leading-none font-bold">
                {{ report.sections.length }} Section
              </span>
              <h3 class="text-base font-light">Total Unique Section</h3>
            </div>
          </div>
        </div>
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <span class="text-2xl leading-none font-bold">
                {{ findingsCount() }} Finding
              </span>
              <h3 class="text-base font-light">Total Unique</h3>
            </div>
          </div>
        </div>
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <span class="text-2xl leading-none font-bold">
                {{ countByKeyInFindingNotNullOrEmpty('recommendation') }} Recommendation
              </span>
              <h3 class="text-base font-light">Total Unique Recommendation</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300"
             v-for="item in ratings" :key="'rating-'+item.id">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <span class="text-2xl leading-none font-bold">
                {{ countByKeyInFinding('rating', item.id) }} {{ item.title }}
              </span>
              <h3 class="text-base font-light">Rating</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8  text-gray-900 dark:bg-gray-800 dark:text-gray-300"
             v-for="item in sentiments"
             :key="'sentiment-'+item.id">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <span class="text-2xl leading-none font-bold">
                {{ countByKeyInFinding('sentiment', item.id) }} {{ item.title }}
              </span>
              <h3 class="text-base font-light">Sentiment</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300"
             v-for="item in efforts" :key="'effort-'+item.id">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <span class="text-2xl leading-none font-bold">
                {{ countByKeyInFinding('effort', item.id) }} {{ item.title }}
              </span>
              <h3 class="text-base font-light">Effort</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">

        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <div class="items-center">
            <h3 class="text-xl font-bold mb-3">Sentiment</h3>
            <Pie :chart-data="sentimentChartData()" :chart-options="chartOptions"/>
          </div>
          <table class="table-auto w-full mt-3">
            <tbody>
            <tr v-for="item in sentiments" :key="'sentiment-'+item.id">
              <td class="border-b border-gray-200 px-4 py-2">
                  <span class=" whitespace-no-wrap">
                    {{ item.title }}
                  </span>
              </td>
              <td class="border-b border-gray-200 px-4 py-2 text-right">
                  <span class=" whitespace-no-wrap">
                    {{ countByKeyInFinding('sentiment', item.id) }}
                  </span>
              </td>
            </tr>
            </tbody>
          </table>

        </div>

        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <div class="items-center">
            <h3 class="text-xl font-bold mb-3">Rating</h3>
            <Pie :chart-data="ratingChartData()" :chart-options="chartOptions"/>
            <table class="table-auto w-full mt-3">
              <tbody>
              <tr v-for="item in ratings" :key="'rating-'+item.id">
                <td class="border-b border-gray-200 px-4 py-2">
                  <span class="whitespace-no-wrap">
                    {{ item.title }}
                  </span>
                </td>
                <td class="border-b border-gray-200 px-4 py-2 text-right">
                  <span class="whitespace-no-wrap">
                    {{ countByKeyInFinding('rating', item.id) }}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>

        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <div class="items-center">
            <h3 class="text-xl font-bold mb-3">Effort</h3>
            <Pie :chart-data="effortChartData()" :chart-options="chartOptions"/>
            <table class="table-auto w-full mt-3">
              <tbody>
              <tr v-for="item in efforts" :key="'effort-'+item.id">
                <td class="border-b border-gray-200 px-4 py-2">
                  <span class="whitespace-no-wrap">
                    {{ item.title }}
                  </span>
                </td>
                <td class="border-b border-gray-200 px-4 py-2 text-right">
                  <span class=" whitespace-no-wrap">
                    {{ countByKeyInFinding('effort', item.id) }}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>


      </div>

    </div>
    <div v-show="currentTab === 'categoryAndLabels'">
      <div class="mt-4 w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4">
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <div class="items-center">
            <h3 class="text-xl font-bold mb-3">Categories</h3>
            <Pie :chart-data="categoryChartData()" :chart-options="chartOptions"/>
          </div>
          <table class="table-auto w-full mt-3">
            <tbody>
            <tr v-for="item in categoriesOrderedByCount()" :key="'category-'+item.id">
              <td class="border-b border-gray-200 px-4 py-2">
                <span class="whitespace-no-wrap capitalize">
                  {{ item.title }}
                </span>
              </td>
              <td class="border-b border-gray-200 px-4 py-2 text-right">
                <span class=" whitespace-no-wrap">
                  {{ item.count }}
                </span>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <div class="items-center">
            <h3 class="text-xl font-bold mb-3">Labels</h3>
            <Pie :chart-data="labelsChartData()" :chart-options="chartOptions"/>
          </div>
          <table class="table-auto w-full mt-3">
            <tbody>
            <tr v-for="item in labelsOrderedByCount()" :key="'label-'+item.id">
              <td class="border-b border-gray-200 px-4 py-2">
                <span class="whitespace-no-wrap capitalize">
                  {{ item.title }}
                </span>
              </td>
              <td class="border-b border-gray-200 px-4 py-2 text-right">
                <span class="whitespace-no-wrap">
                  {{ item.count }}
                </span>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
      <div class="mt-4 w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4">
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <div class="items-center">
            <h3 class="text-xl font-bold mb-3">All Categories</h3>
          </div>
          <table class="table-auto w-full mt-3">
            <tbody>
            <tr v-for="item in categoriesAllOrderByCount()" :key="'category-'+item.id">
              <td class="border-b border-gray-200 px-4 py-2">
                <span class="whitespace-no-wrap capitalize">
                  {{ item.title }}
                </span>
              </td>
              <td class="border-b border-gray-200 px-4 py-2 text-right">
                <span class=" whitespace-no-wrap">
                  {{ item.count }}
                </span>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <div class="items-center">
            <h3 class="text-xl font-bold mb-3">All Labels</h3>
          </div>
          <table class="table-auto w-full mt-3">
            <tbody>
            <tr v-for="item in labelsAllWithCountOrderByCount()" :key="'labels-'+item.id">
              <td class="border-b border-gray-200 px-4 py-2">
                <span class="whitespace-no-wrap capitalize">
                  {{ item.title }}
                </span>
              </td>
              <td class="border-b border-gray-200 px-4 py-2 text-right">
                <span class=" whitespace-no-wrap">
                  {{ item.count }}
                </span>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
    <div v-show="currentTab === 'findings'">
      <div class="flex flex-wrap">
        <div v-for="item in findings()" :key="item.id" class="w-full py-4 md:w-6/6 lg:w-6/6"
             :data-finding-id="item.id"
        >
          <div
              class="finding-item transition-all ease-linear duration-200 block bg-white shadow-md hover:shadow-xl overflow-hidden hover:bg-gray-50 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

            <div class="p-4 pb-2">
              <div class="flex justify-between">
                      <span class="flex-1">
                            <span
                                class="bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2 py-1 rounded dark:bg-yellow-200 dark:text-yellow-900">
                              {{ item.category_str }}
                            </span>

                                   <span v-for="(label, index) in item.labels_str" :key="index"
                                         class="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">
                                     #{{ label }}</span>


                      </span>
                <div>

                </div>

                <span
                    class="bg-gray-100 text-gray-400 text-xs font-semibold px-2 py-1 rounded dark:bg-gray-700 dark:text-gray-300 ">{{
                    item.created_at | moment('DD/MM/YYYY')
                  }}</span>

              </div>
              <h2 class="mt-2 mb-2 text-sm font-bold dark:text-white">
                {{ item.finding }}
              </h2>
              <p class="text-sm font-light dark:text-white">
                {{ item.recommendation }}
              </p>
            </div>
            <div class="p-3 border-t text-xs text-gray-700  dark:border-gray-900">
              <div class="flex flex-wrap">
                <div class="mr-3">
                  <span class="ml-2 text-xs font-light dark:text-white">Sentiment</span>
                  <span class="block ml-2 text-sm font-bold dark:text-white">{{ item.sentiment_str }}</span>
                </div>
                <div class="mr-3">
                  <span class="ml-2 text-xs font-light dark:text-white">Effort</span>
                  <span class="block ml-2 text-sm font-bold dark:text-white">{{ item.effort_str }}</span>
                </div>
                <div class="mr-3">
                  <span class="ml-2 text-xs font-light dark:text-white">Rating</span>
                  <span class="block ml-2 text-sm font-bold dark:text-white">{{ item.rating_str }}</span>
                </div>
                <div class="flex-1 text-right pr-2">
                  <span class="ml-2 text-xs font-thin dark:text-white">Report by</span>
                  <span class="block ml-2 text-xs font-light dark:text-white">{{ item.creator_full_name }}</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div v-show="currentTab === 'filter'">
      <div class="mt-4 w-full grid grid-cols-1">
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <v-grid theme="material" :source="findings()"
                  :columns="columns"
                  :filter="true"
                  :col-size="150"
                  :auto-size="true"
                  :auto-size-column="true"
                  :readonly="true"
                  :resize="true"
                  :stretch="true"
                  :use-clipboard="true"
          ></v-grid>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import VGrid from "@revolist/vue-datagrid";
import {Pie} from 'vue-chartjs/legacy'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

export default {
  data() {
    return {
      isReady: false,
      currentTab: 'general',
      tabs: [
        {id: 'general', title: 'General Stats'},
        {id: 'categoryAndLabels', title: 'Category & Labels'},
        {id: 'findings', title: 'Findings'},
        {id: 'filter', title: 'Filter'},
      ],
      chartOptions: {
        responsive: true,
        aspectRatio: 1,
        maintainAspectRatio: false,
      },
      columns: [
        {
          prop: "category_str",
          name: "Category",
          editor: 'select',
        },
        {
          prop: "labels_str",
          name: "labels",
        },
        {
          prop: "effort_str",
          name: "Effort",
        },
        {
          prop: "rating_str",
          name: "Rating",
        },
        {
          prop: "sentiment_str",
          name: "Sentiment",
        }, {
          prop: "sentiment_str",
          name: "Sentiment",
        },
        {
          prop: "section_str",
          name: "Section",
        },
        {
          prop: "finding",
          name: "Finding",
        },
        {
          prop: "recommendation",
          name: "Recommendation",
        },
        {
          prop: "creator_full_name",
          name: "Creator",
        },
        {
          prop: "created_at",
          name: "Created At",
        },
      ],
    }
  },
  components: {
    Pie,
    VGrid
  },
  computed: {
    report() {
      return this.$store.getters['report/report'];
    },
    categories() {
      return this.$store.getters["tags/categories"];
    },
    labels() {
      return this.$store.getters["tags/labels"];
    },
    sentiments() {
      return this.$store.getters['sentiments'];
    },
    ratings() {
      return this.$store.getters['ratings'];
    },
    efforts() {
      return this.$store.getters['efforts'];
    }
  },
  methods: {
    findings() {
      let findings = [];
      this.report.sections.forEach(section => {
        section.findings.forEach(finding => {
          finding['section_str'] = section.title;
          findings.push(finding);
        });
      });
      return findings;
    },
    countByKeyInFinding: function (key, value) {
      return this.report.sections.reduce((acc, section) => {
        return acc + section.findings.reduce((acc, finding) => {
          return acc + (finding[key] === value ? 1 : 0)
        }, 0)
      }, 0)
    },
    countByKeyInFindingNotNullOrEmpty: function (key) {
      return this.report.sections.reduce((acc, section) => {
        return acc + section.findings.reduce((acc, finding) => {
          return acc + (finding[key] !== null && finding[key] !== '' ? 1 : 0)
        }, 0)
      }, 0)
    },
    colorsFromArray: function (key) {
      return this[key].map(item => item.color)
    },
    findingsCount() {
      let count = 0
      this.report.sections.forEach((item) => {
        count += item.findings.length
      })
      return count
    },
    randomHexColor() {
      return '#' + Math.floor(Math.random() * 16777215).toString(16)
    },
    hexColorGeneratorXTimes: function (x) {
      let colors = []
      for (let i = 0; i < x; i++) {
        colors.push(this.randomHexColor())
      }
      return colors
    },
    sentimentChartData() {
      return {
        labels: [
          'Negative',
          'Neutral',
          'Positive'
        ],
        datasets: [{
          label: 'Sentiment',
          data: [
            this.countByKeyInFinding('sentiment', 1),
            this.countByKeyInFinding('sentiment', 2),
            this.countByKeyInFinding('sentiment', 3)
          ],
          backgroundColor: this.colorsFromArray('sentiments'),
          hoverOffset: 5,
        }]
      }
    },
    ratingChartData() {
      return {
        labels: [
          'Critical',
          'Major',
          'Minor'
        ],
        datasets: [{
          label: 'Rating',
          data: [
            this.countByKeyInFinding('rating', 1),
            this.countByKeyInFinding('rating', 2),
            this.countByKeyInFinding('rating', 3)
          ],
          backgroundColor: this.colorsFromArray('ratings'),
          hoverOffset: 5,
        }]
      }
    },
    effortChartData() {
      return {
        labels: [
          'None',
          'High',
          'Mid',
          'Low'
        ],
        datasets: [{
          label: 'Effort',
          data: [
            this.countByKeyInFinding('effort', 0),
            this.countByKeyInFinding('effort', 1),
            this.countByKeyInFinding('effort', 2),
            this.countByKeyInFinding('effort', 3)
          ],
          backgroundColor: this.colorsFromArray('efforts'),
          hoverOffset: 5,
        }]
      }
    },
    categoriesAllWithCount() {
      let categories = []
      this.categories.forEach((category) => {
        let count = this.countByKeyInFinding('category', category.id)
        categories.push({
          id: category.id,
          title: category.title,
          count: count
        })
      })
      return categories
    },
    categoriesAllOrderByCount() {
      return this.categoriesAllWithCount().sort((a, b) => {
        return b.count - a.count
      })
    },
    categoriesWithCount() {
      let categories = []
      this.categories.forEach((category) => {
        let count = this.countByKeyInFinding('category', category.id)
        if (count > 0) {
          categories.push({
            id: category.id,
            title: category.title,
            count: count
          })
        }
      })
      return categories
    },
    valuesFromCategoriesWithCount(key) {
      return this.categoriesWithCount().map(item => item[key])
    },
    categoriesOrderedByCount() {
      return this.categoriesWithCount().sort((a, b) => b.count - a.count)
    },
    categoryChartData() {
      return {
        labels: this.valuesFromCategoriesWithCount('title'),
        datasets: [{
          label: 'Category',
          data: this.valuesFromCategoriesWithCount('count'),
          backgroundColor: this.hexColorGeneratorXTimes(this.categories.length),
          hoverOffset: 5,
        }]
      }
    },
    labelsAllWithCount() {
      let labels = []
      this.labels.forEach((label) => {
        let count = this.report.sections.reduce((acc, section) => {
          return acc + section.findings.reduce((acc, finding) => {
            return acc + (finding.labels.includes(label.id) ? 1 : 0)
          }, 0)
        }, 0)

        labels.push({
          id: label.id,
          title: label.title,
          count: count
        })

      })
      return labels
    },
    labelsAllWithCountOrderByCount() {
      return this.labelsAllWithCount().sort((a, b) => {
        return b.count - a.count
      })
    },
    labelsWithCount() {
      let labels = []
      this.labels.forEach((label) => {
        let count = this.report.sections.reduce((acc, section) => {
          return acc + section.findings.reduce((acc, finding) => {
            return acc + (finding.labels.includes(label.id) ? 1 : 0)
          }, 0)
        }, 0)
        if (count > 0) {
          labels.push({
            id: label.id,
            title: label.title,
            count: count
          })
        }
      })
      return labels
    },
    labelsOrderedByCount() {
      return this.labelsWithCount().sort((a, b) => b.count - a.count)
    },
    labelsChartData() {
      return {
        labels: this.labelsWithCount().map(item => item.title),
        datasets: [{
          label: 'labels',
          data: this.labelsWithCount().map(item => item.count),
          backgroundColor: this.hexColorGeneratorXTimes(this.labels.length),
          hoverOffset: 5,
        }]
      }
    },
  },
  async created() {
    await this.$store.dispatch('report/get', {
      project_id: this.$route.params.project_id,
    })
    await this.$store.dispatch('projects/get', {
      project_id: this.$route.params.project_id,
    })
    await this.$store.dispatch('tags/init', {
      project_id: this.$route.params.project_id,
    })
    this.$store.commit('SET_LOADING', false, {root: true})
    this.isReady = true;
  },

};
</script>
<style lang="scss" scoped>
revo-grid {
  height: 500px;
  font-family: unset;
}

.report {
  h2, .h2 {
    font-size: 18px;
  }

  h5, .h5 {
    font-size: 13px;
  }

  .body {
    padding-top: 40px;
    position: relative;
  }
}

.sub-title {
  margin: 30px 0 0 0;
}

.nav-header {
  position: absolute;
  top: 71px;
  left: 0;
  right: 0;
  background: white;
  padding: 15px 15px 0 15px;
  border-bottom: 1px solid #e0e0e0;
  z-index: 9;

  .nav {
    border-bottom: 0;
    @media (max-width: 767px) {
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
    }

    .nav-item {
      white-space: nowrap;
    }

    .nav-link {
      cursor: pointer;
      color: rgba(120, 85, 244, 0.5);
      font-weight: 700;
      position: relative;

      &.active {
        background-color: #f7faff;
        color: #7855f4;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          height: 1px;
          width: 100%;
          background-color: #f7fafe;
          bottom: -1px;
          z-index: 9999;
        }
      }
    }
  }
}


hr {
  margin: 30px 0 20px 0;
  display: inline-block;
  width: 100%;
}

.screen-and-finding {
  display: flex;
}

.screen {
  background-color: white;
  padding: 30px;
  margin: 30px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  //box-shadow: rgba(50, 50, 93, 0.1) 0 30px 60px -12px inset, rgba(0, 0, 0, 0.0) 0px 18px 36px -18px inset;
  .download-screen {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 12px;
    cursor: pointer;
  }

  .watermark {
    position: absolute;
    right: 30px;
    top: 30px;

    img {
      height: 50px;
    }
  }

  @media (max-width: 768px) {
    padding: 15px;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
  }

  &__image {
    @media (max-width: 768px) {
      display: none;
    }
  }

  &__img {
    width: 300px;
    box-shadow: rgba(0, 0, 0, 0.15) 0 13px 27px -5px, rgba(0, 0, 0, 0.2) 0px 8px 16px -8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
  }
}

.findings {
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0;
  }
}

.finding__group {
  $self: &;

  &__title {
    font-family: 'Oswald', sans-serif;
    color: #7954f3;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 20px;
    margin: 0 0 10px 0;
  }

  &__list {
    @media (max-width: 768px) {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      margin-bottom: 20px;
      @media (max-width: 768px) {
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      &:last-child {
        @media (max-width: 768px) {
          border: none;
        }
      }
    }
  }

  &.negative {
    #{$self}__title {
      color: #F24913;
    }
  }

  &.positive {
    #{$self}__title {
      color: #9fcd4b;
    }
  }

  &.recommendation {
    #{$self}__title {
      color: #355CD6;
    }
  }
}

.card-stats {
  @media (max-width: 768px) {
    margin: 20px 0 0 0;
  }

  .card-body {
    padding: 0;
  }
}

.effort {
  font-size: 13px;

  &:before {
    content: '-'
  }
}

.severity {
  display: inline-block;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: white;
  padding: 1px 5px;
  position: relative;
  top: -2px;
  text-align: center;

  &.high {
    background-color: rgba(242, 73, 19, 0.75);
  }

  &.mid {
    background-color: rgba(242, 73, 19, 0.5);
  }

  &.low {
    background-color: rgba(242, 73, 19, 0.25);
  }
}

.criteria {
  display: inline-block;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #607d8b;
  padding: 1px 5px;
  position: relative;
  top: -2px;
  text-align: center;
}
</style>
