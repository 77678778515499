<template>
  <div data-page-findings class="flex flex-wrap">

    <div class="md:w-4/12 p-4">
      <div class="bg-white shadow-md p-4 dark:bg-gray-800">
        <div class="relative" @click="clicked($event)">
          <SectionImage :section-id="$route.params.section_id" class="border min-w-full"></SectionImage>
          <div id="pins" class="pins" ref="pins">
            <div class="pin new"
                 v-if="createOrUpdate"
                 :style="'left:'+finding.axis_x+'%; top:'+finding.axis_y+'%;'"></div>
            <template v-if="!hideFindings">
            <span class="pin" v-for="(item,index) in items" v-bind:key="index"
                  :data-finding-item-id="item.id"
                  v-bind:class="{ active: selectedFinding === item.id }"
                  :style="'left:'+item.axis_x+'%; top:'+item.axis_y+'%;'"
                  @mousedown="selectedFinding = item.id">
                  {{ index + 1 }}
            </span>
            </template>

          </div>
        </div>
      </div>
    </div>
    <div class="md:w-8/12">
      <div class="flex flex-wrap">
        <div class="md:w-5/12">
          <div class="bg-white p-4 shadow mt-4 dark:bg-gray-700 relative">
            <div class="grid gap-4 mb-4 sm:grid-cols-2 ">
              <div v-if="!createOrUpdate" class="select-pin-overlay">Please Select</div>
              <div v-if="categories" class="sm:col-span-2">
                <label for="category" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Category</label>
                <multiselect
                    id="category"
                    v-model="finding.category"
                    :options="categories"
                    :searchable="true"
                    :taggable="true"
                    @tag="addCategory"
                    label="title"
                    track-by="id"
                    placeholder="Pick a category"></multiselect>
              </div>


              <div class="sm:col-span-2">

                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Sentiment</label>

                <div class="flex  items-center">
                  <div class="flex items-center mr-4" v-for="item in sentiments" :key="item.id">
                    <input :id="'sentiment-'+ item.id" type="radio" v-model="finding.sentiment" name="type"
                           :value="item.id"
                           class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                    <label :for="'sentiment-'+ item.id"
                           class="ml-2 text-sm font-light text-gray-900 dark:text-gray-300">{{ item.title }}</label>
                  </div>
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="labels"
                       class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Labels</label>
                <div v-if="labels">
                  <multiselect
                      id="category"
                      v-model="finding.labels"
                      :options="labels"
                      :searchable="true"
                      :taggable="true"
                      :multiple="true"
                      @tag="addLabel"
                      label="title"
                      track-by="id"
                      placeholder="Pick a labels"></multiselect>
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="finding"
                       class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Finding</label>
                <textarea id="finding" rows="4"
                          class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Write finding here" v-model="finding.finding"></textarea>
              </div>
              <div class="sm:col-span-2">
                <label for="recommendation" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Recommendation</label>
                <textarea id="recommendation" rows="4"
                          class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Write recommendation here" v-model="finding.recommendation"></textarea>
              </div>

              <div class="sm:col-span-2">
                <label for="note" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Note</label>
                <textarea id="note" rows="4"
                          class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Write note here. Only the team can see this area. It is not included in reports."
                          v-model="finding.note"></textarea>
              </div>

              <div class="sm:col-span-2">
                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Rating of Issue</label>
                <div class="flex items-center">
                  <div class="flex items-center mr-4" v-for="item in ratings" :key="item.id">
                    <input :id="'rating-'+ item.id" type="radio" v-model="finding.rating" name="rating" :value="item.id"
                           class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                    <label :for="'rating-'+ item.id"
                           class="ml-2 text-sm font-light text-gray-900 dark:text-gray-300">{{ item.title }}</label>
                  </div>
                </div>
              </div>

              <div class="sm:col-span-2">
                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Effort</label>
                <div class="flex  items-center">
                  <div class="flex items-center mr-4" v-for="item in efforts" :key="item.id">
                    <input :id="'effort-'+ item.id" type="radio" v-model="finding.effort" name="effort" :value="item.id"
                           class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                    <label :for="'effort-'+ item.id"
                           class="ml-2 text-sm font-light text-gray-900 dark:text-gray-300">{{ item.title }}</label>
                  </div>
                </div>
              </div>


            </div>
            <button @click="isEdit ? update() : create()"
                    type="button"
                    class="text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
              {{ isEdit ? 'UPDATE' : 'CREATE' }}
            </button>
          </div>
        </div>
        <div class="md:w-7/12">
          <div class="findings">
            <div class="flex justify-end pt-4 pl-4 pr-4 hidden">
              <button @click="clicked()" type="button"
                      class="inline-flex items-center px-4 py-2 text-sm text-center text-white bg-green-500 rounded-lg hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                Create a new project
              </button>
            </div>

            <div class="flex flex-wrap">
              <div v-for="item in itemsReversed" :key="item.id" class="w-full p-4 md:w-6/6 lg:w-6/6"
                   :data-finding-id="item.id"

              >
                <div
                    class="finding-item transition-all ease-linear duration-200 block bg-white shadow-md hover:shadow-xl overflow-hidden hover:bg-gray-50 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                    :class="{'selected-finding dark:bg-purple-900 dark:hover:bg-purple-800' : selectedFinding === item.id}"
                >

                  <div class="p-4 pb-2">
                    <div class="flex justify-between">
                      <span class="flex-1">
                            <span
                                class="bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2 py-1 rounded dark:bg-yellow-200 dark:text-yellow-900">
                              {{ item.category_str }}
                            </span>

                                   <span v-for="(label, index) in item.labels_str" :key="index"
                                         class="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">
                                     #{{ label }}</span>


                      </span>
                      <div>

                      </div>

                      <span
                          class="bg-gray-100 text-gray-400 text-xs font-semibold px-2 py-1 rounded dark:bg-gray-700 dark:text-gray-300 ">{{
                          item.created_at | moment('DD/MM/YYYY')
                        }}</span>

                      <span
                          @click="edit(item.id)"
                          class="edit-finding bg-blue-700 text-white text-xs font-semibold px-2 py-1 rounded dark:bg-blue-700 dark:text-gray-300 ml-2 cursor-pointer">
                        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round"
                                                                      stroke-width="2"
                                                                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
                      </span>
                      <span
                          @click="destroy(item.id)"
                          class="edit-finding bg-red-700 text-white text-xs font-semibold px-2 py-1 rounded dark:bg-red-700 dark:text-gray-300 ml-2 cursor-pointer">
                         <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round"
                                                                       stroke-width="2"
                                                                       d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                       </span>

                    </div>
                    <h2 class="mt-2 mb-2 text-sm font-bold dark:text-white">
                      {{ item.finding }}
                    </h2>
                    <p class="mt-2 mb-2 text-sm font-light dark:text-white">
                      {{ item.recommendation }}
                    </p>
                    <p class="text-sm font-light dark:text-white font-italic">
                      <em>{{ item.note }}</em>
                    </p>
                  </div>
                  <div class="p-3 border-t text-xs text-gray-700  dark:border-gray-900">
                    <div class="flex flex-wrap">
                      <div class="mr-3">
                        <span class="ml-2 text-xs font-light dark:text-white">Sentiment</span>
                        <span class="block ml-2 text-sm font-bold dark:text-white">{{ item.sentiment_str }}</span>
                      </div>
                      <div class="mr-3">
                        <span class="ml-2 text-xs font-light dark:text-white">Effort</span>
                        <span class="block ml-2 text-sm font-bold dark:text-white">{{ item.effort_str }}</span>
                      </div>
                      <div class="mr-3">
                        <span class="ml-2 text-xs font-light dark:text-white">Rating</span>
                        <span class="block ml-2 text-sm font-bold dark:text-white">{{ item.rating_str }}</span>
                      </div>
                      <div class="flex-1 text-right pr-2">
                        <span class="ml-2 text-xs font-thin dark:text-white">Report by</span>
                        <span class="block ml-2 text-xs font-light dark:text-white">{{ item.creator_full_name }}</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>


  </div>
</template>

<script>
import SectionImage from "../components/SectionImage";

export default {
  name: 'Findings',
  components: {
    SectionImage
  },
  data() {
    return {
      createOrUpdate: false,
      isEdit: false,
      hideFindings: false,
      selectedFinding: null,
      withPin: true,
      empty: {
        axis_x: 0,
        axis_y: 0,
        sentiment: 1,
        rating: 1,
        effort: 0,
        finding: '',
        recommendation: '',
        note: '',
        category: null,
        labels: null
      },
      finding: {
        axis_x: 0,
        axis_y: 0,
        sentiment: 1,
        rating: 1,
        effort: 0,
        finding: '',
        recommendation: '',
        note: '',
        category: null,
        labels: null
      },
    }
  },
  computed: {
    items() {
      return this.$store.getters["findings/list"];
    },
    categories() {
      return this.$store.getters["tags/categories"];
    },
    labels() {
      return this.$store.getters["tags/labels"];
    },
    itemsReversed() {
      return JSON.parse(JSON.stringify(this.items)).reverse()
    },
    sentiments() {
      return this.$store.getters['sentiments'];
    },
    ratings() {
      return this.$store.getters['ratings'];
    },
    efforts() {
      return this.$store.getters['efforts'];
    }
  },
  methods: {
    clicked(event) {
      const elm = document.getElementById('pins').getBoundingClientRect();
      this.finding = JSON.parse(JSON.stringify(this.empty));
      if (event.target.closest('.pin')) {
        this.selectedFinding = event.target.getAttribute('data-finding-item-id');
        this.createOrUpdate = false;
        this.edit(this.selectedFinding)
        return
      }

      const xPos = (event.layerX - 15);
      const yPos = (event.layerY - 15);

      const axis_x = xPos / elm.width * 100;
      const axis_y = yPos / elm.height * 100;
      this.finding.axis_x = axis_x;
      this.finding.axis_y = axis_y;


      this.selectedFinding = null;
      this.isEdit = false;
      this.createOrUpdate = true;


    },
    async create() {
      const self = this;

      if (!this.validateData()) return;

      await this.$store.dispatch('findings/create', {
        params: self.finding,
        project_id: this.$route.params.project_id,
        section_id: this.$route.params.section_id,
      })
      this.createOrUpdate = false;
      this.finding = this.empty;
    },
    async edit(id) {

      if (id === this.selectedFinding) return

      let finding = this.items.find((item) => item.id === id)
      finding = JSON.parse(JSON.stringify(finding));
      finding.category = this.categories.find((item) => item.id === finding.category)

      let labels = [];
      this.labels.forEach((item) => {
        if (finding.labels.includes(item.id)) {
          labels.push(item)
        }
      })
      finding.labels = labels;

      this.isEdit = true;
      this.createOrUpdate = true;
      this.selectedFinding = finding.id;
      this.finding = finding;
    },
    async update() {
      const self = this;

      if (!this.validateData()) return;

      await this.$store.dispatch('findings/update', {
        params: self.finding,
        project_id: this.$route.params.project_id,
        section_id: this.$route.params.section_id,
        finding_id: self.selectedFinding,
      })
      this.createOrUpdate = false;
      this.finding = this.empty;
    },
    async destroy(id) {

      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$store.dispatch('findings/delete', {
            project_id: this.$route.params.project_id,
            section_id: this.$route.params.section_id,
            finding_id: id,
          })

          this.createOrUpdate = false;
          this.finding = this.empty;
          this.selectedFinding = null;
          this.isEdit = false;
        }
      })


    },
    async addCategory(title) {
      await this.$store.dispatch('tags/addCategory', {
        project_id: this.$route.params.project_id,
        title: title
      }).then(() => {
        const categories = JSON.parse(JSON.stringify(this.categories))
        const category = categories.find((item) => item.title === title)
        this.finding.category = category;
      })
    },
    async addLabel(title) {
      await this.$store.dispatch('tags/addLabel', {
        project_id: this.$route.params.project_id,
        title: title
      }).then(() => {
        const labels = JSON.parse(JSON.stringify(this.labels))
        const label = labels.find((item) => item.title === title)
        if (!this.finding.labels) {
          this.finding.labels = [];
        }
        this.finding.labels = [...this.finding.labels, label];
      })
    },
    validateData() {
      if (!this.finding.category || !this.finding.labels) {
        this.$store.commit('SET_ERROR', {
          "Ups": ["Please fill in all fields."]
        }, {root: true})
        return
      }
      return true
    }
  },
  created() {
    this.$store.dispatch('findings/init', {
      project_id: this.$route.params.project_id,
      section_id: this.$route.params.section_id,
    })
    this.$store.dispatch('tags/init', {
      project_id: this.$route.params.project_id,
    })

    this.$store.dispatch('projects/get', {
      project_id: this.$route.params.project_id,
    })

    this.$store.dispatch('sections/get', {
      project_id: this.$route.params.project_id,
      section_id: this.$route.params.section_id,
    })
  }
}
</script>
<style lang="scss" scoped>
.pins {
  width: 100%;
  min-height: 100%;
  display: flex;
  overflow: hidden;
  border-radius: 7px;
  touch-action: none;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  cursor: copy;

}

.pin {
  position: absolute;
  width: 40px;
  height: 40px;
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);

  &:hover {
    cursor: pointer;
  }

  &.active {
    background: #673ab7;
    border-color: white;
    animation: pulse 1s infinite;
  }

  &.new {
    z-index: 99;
    background-color: #6cc800;
    animation: pulse 1s infinite;
  }
}

.customModal {
  background-color: rgba(0, 0, 0, 0.7);
}

.edit-finding {
  display: none;
}

.selected-finding, .finding-item:hover {
  .edit-finding {
    display: inline-block;
  }
}

.findings {
  max-height: calc(100vh - 70px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.select-pin-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #b3b3b3;
  z-index: 9;
  border: 5px dashed rgba(255, 255, 255, 0.4);
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
