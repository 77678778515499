<template>
  <div data-page-sections>
    <image-upload></image-upload>

    <div class="flex flex-wrap lg:-mx-4" v-if="project">
      <div class=" my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3" v-for="item in items" :key="item.id">
        <div
            class="item flex flex-col shadow bg-white rounded-md p-10 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <router-link
              :to="{ name:  project.type === 1 ? 'Contents' : 'Findings' , params: { project_id: $route.params.project_id, section_id: item.id}}"
              v-if="project.type === 2"
              class="text-base font-light dark:text-white mb-4 h-60 max-h-60 overflow-hidden">
            <SectionImage :section-id="item.id"></SectionImage>
          </router-link>
          <div class="flex flex-col justify-between">
            <router-link
                :to="{ name:  project.type === 1 ? 'Contents' : 'Findings' , params: { project_id: $route.params.project_id, section_id: item.id}}">
              <div class="text-lg text-purple-700 md:text-xl dark:text-white font-extrabold">{{ item.title }}</div>
              <div class="text-md font-light text-gray-700 dark:text-white">{{ item.findings_count }}
                {{ project.type === 1 ? 'Content' : 'Finding' }}
              </div>
            </router-link>
            <div class="relative">
              <div class="flex items-center">
                <div class="dark:text-gray-400">
                  <div class="text-xs font-light">{{ item.creator_full_name }}, {{
                      item.created_at | moment('calendar')
                    }}
                  </div>
                </div>
              </div>
              <div class="absolute right-1 bottom-2">
                <span
                    @click="destroy(item.id)"
                    class="remove-button bg-red-700 text-white text-xs font-semibold px-2 py-1 rounded dark:bg-red-700 dark:text-gray-300 ml-2 cursor-pointer">DELETE</span>

              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>

import SectionImage from "../components/SectionImage";
import ImageUpload from "../components/ImageUpload.vue";

export default {
  name: 'Sections',
  components: {SectionImage, ImageUpload},
  data() {
    return {
      showNewModal: false,
      form: {
        title: '',
        image: null,
      }
    }
  },
  computed: {
    items() {
      return this.$store.getters["sections/list"];
    },
    project() {
      return this.$store.getters["projects/current"];
    },
    section() {
      return this.$store.getters["sections/current"];
    },
    formIsValid() {
      if (this.project && this.project.type === 2) {
        return this.form.title.length > 0 && this.form.image
      } else {
        return this.form.title.length > 0
      }
    }
  },
  methods: {
    async submit() {
      let params = new FormData();
      params.append('title', this.form.title);
      if (this.project && this.project.type === 2) {
        params.append('image', this.form.image);
      }

      await this.$store.dispatch('sections/create', {
        params: params,
        project_id: this.$route.params.project_id,
      })
      this.showNewModal = false;
      this.form.title = ''
      this.form.image = ''
      if (this.project && this.project.type === 2) {
        this.$refs.file.value = null;
        document.getElementById('preview').innerHTML = '';
      }

    },
    uploadFile() {
      this.form.image = this.$refs.file.files[0];
      let reader = new FileReader()
      reader.readAsDataURL(this.$refs.file.files[0])
      reader.onloadend = function () {
        let img = document.createElement('img')
        img.src = reader.result;
        document.getElementById('preview').innerHTML = '';
        document.getElementById('preview').append(img)
      }
    },
    async destroy(id) {

      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$store.dispatch('sections/delete', {
            project_id: this.$route.params.project_id,
            section_id: id,
          })
        }
      })


    },
  },
  created() {
    this.$store.dispatch('sections/init', {
      project_id: this.$route.params.project_id,
    })
    this.$store.dispatch('projects/get', {
      project_id: this.$route.params.project_id,
    })
  }
}
</script>
<style lang="scss" scoped>
[data-page-sections] {
  .remove-button {
    display: none;
  }

  .item:hover {
    .remove-button {
      display: inline-block;
    }
  }
}
</style>
